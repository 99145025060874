import Chart from 'chart.js/auto';

if (document.querySelectorAll('[data-component="chart"]').length > 0) {

    document.addEventListener('DOMContentLoaded', function () {
        const ctx = document.getElementById("myChart").getContext('2d');

        const myChart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ],
                datasets: [{
                    label: 'Bank Transfer', // Name the series
                    data: [500,	50,	2424, 14040, 14141,	4111, 4544,	47,	5555, 6811], // Specify the data values array
                    fill: false,
                    borderColor: '#2196f3', // Add custom color border (Line)
                    backgroundColor: '#2196f3', // Add custom color background (Points and Fill)
                    borderWidth: 1 // Specify bar border width
                }, {
                    label: 'Credit Card', // Name the series
                    data: [1288, 88942,	44545, 7588, 99, 242, 1417, 5504, 75, 457], // Specify the data values array
                    fill: false,
                    borderColor: '#4CAF50', // Add custom color border (Line)
                    backgroundColor: '#4CAF50', // Add custom color background (Points and Fill)
                    borderWidth: 1 // Specify bar border width
                }]
            },
            options: {
                responsive: true, // Instruct chart js to respond nicely.
                maintainAspectRatio: false, // Add to prevent default behaviour of full-width/height 
                plugins: {
                    title: {
                        display: true,
                        text: 'Revenue by Payment Method (2022)'
                    }
                }
            }
        });
    })
}